exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-heartworks-js": () => import("./../../../src/pages/heartworks.js" /* webpackChunkName: "component---src-pages-heartworks-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-templates-heartwork-details-js": () => import("./../../../src/templates/heartwork-details.js" /* webpackChunkName: "component---src-templates-heartwork-details-js" */),
  "component---src-templates-project-details-js": () => import("./../../../src/templates/project-details.js" /* webpackChunkName: "component---src-templates-project-details-js" */)
}

